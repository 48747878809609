import {React, useState} from 'react';
import "../../style/BasicLogin.css";
import GLOBAL_VARIABLES from '../globalVariables';
import DataTransitionModal from './DataTransitionModal';

import {
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    IconButton,
    Button,
    Input,
    Stack,
    Alert
  } from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginIcon from "@mui/icons-material/Login";
// import { graphQLFetch } from "../helper/HelperFucntion";

const transition_message = GLOBAL_VARIABLES["basic-login-transition-message"];

const authenticate = async(loginInfo) => {
    try {
        // Mock Response
        const result = {
            authenticate: true
        };

        // const query = `query authenticate($loginInfo: InputLogin!)
        // { authenticate(loginInfo: $loginInfo) }`;

        // const result = await graphQLFetch(query, { loginInfo });
        // console.log("Resposen from GQL server: ", { result });

        return result.authenticate;
    } catch (e) {
        alert(`Error in authenticating the traveller : ${e.message}`);
    }
}

function BasicLoginForm() {
    const [showPassword, setShowPassword] = useState(false);

    // User Inputs
    const [usernameOrEmailInput, setUsernameOrEmailInput] = useState();
    const [passwordInput, setPasswordInput] = useState();
    const [confirmPasswordInput, setConfirmPasswordInput] = useState();

    // Inputs Errors
    const [usernameOrEmailError, setUsernameOrEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    // Overall Form Validity
    const [formValid, setFormValid] = useState();
    const [success, setSuccess] = useState();

    // Set Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalData, setModalData] = useState({});

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    // Internal Functions
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUsernameOrEmailInput = () => {
        if(!usernameOrEmailInput ){
            setUsernameOrEmailError(true);
            return;
        };

        setUsernameOrEmailError(false);
    }

    const handlePassword = () => {
        if (
            !passwordInput ||
            passwordInput.length < 5 ||
            passwordInput.length > 20
        ) {
            setPasswordError(true);
            return;
        }
        setPasswordError(false);
    };

    const validationCheck = () => {
        if (usernameOrEmailError || !usernameOrEmailInput) {
            setFormValid(
            "Username or email should not be empty"
            );
            return;
        }
        if (passwordError || !passwordInput) {
            setFormValid(
            "Password is set btw 5 - 20 characters long. Please Re-Enter"
            );
            return;
        }
        if (passwordError || !confirmPasswordInput) {
            setFormValid(
            "Confirm Password is set btw 5 - 20 characters long. Please Re-Enter"
            );
            return;
        }
        setFormValid(null);
    }

    const handleSubmit = async() => {

        const loginInfo = {
            email: usernameOrEmailInput,
            password: passwordInput,
        }

        // validation check
        validationCheck();

        // If validation check is passed, then authentication user information to the back-end server
        const isUserAccepted = await authenticate(loginInfo);
        console.log(isUserAccepted);

        // After login authentication checked, it receives the result
        if (isUserAccepted) {
            setSuccess("Login Authentication Successfull");
            setModalData(loginInfo); 
            setModalMessage(transition_message);
            handleOpenModal();
        } else {
            setFormValid("Login Failed");
        }
    };

    return(
        <div className="inputForm">
            <p>
                <TextField 
                    id="standard-basic"
                    label="Username / Email"
                    variant="standard"
                    fullWidth
                    size="small"
                    value={usernameOrEmailInput}
                    error={usernameOrEmailError}
                    InputProps={{}}
                    onChange={(event) => {
                      setUsernameOrEmailInput(event.target.value);
                    }}
                    onBlur={handleUsernameOrEmailInput}                    
                />
            </p>
            <p>
                <FormControl sx={{ m: 1, width: '100%', marginX:0, marginY:0 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={passwordInput}
                        error={passwordError}
                        onChange={(event) => {
                          setPasswordInput(event.target.value);
                        }}
                        onBlur={handlePassword}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            </p>
            <p>
                <FormControl sx={{ m: 1, width: '100%', marginX:0, marginY:0 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPasswordInput}
                        error={passwordError}
                        onChange={(event) => {
                          setConfirmPasswordInput(event.target.value);
                        }}
                        onBlur={handlePassword}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            </p>

            <div style={{ marginTop: "30px" }}>
                <Button
                    variant="contained"
                    fullWidth
                    startIcon={<LoginIcon />}
                    onClick={handleSubmit}
                >
                LOG IN
                </Button>
            </div>

            {formValid && (
              <Stack sx={{ width: "100%", paddingTop: "10px" }} spacing={2}>
                <Alert severity="error" size="small">
                  {formValid}
                </Alert>
              </Stack>
            )}

            {success && (
              <Stack sx={{ width: "100%", paddingTop: "10px" }} spacing={2}>
                <Alert severity="success" size="small">
                  {success}
                </Alert>
              </Stack>
            )}

            <div style={{ marginTop: "15px", fontSize: "10px" }} margin="left">
              <a>Forgot Password ?</a>
            </div>

            <DataTransitionModal open={openModal} handleClose={handleCloseModal} data={modalData} message={modalMessage} />
        </div>
    );
}

export default BasicLoginForm;
