import React, { useState, useEffect } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import BeatLoader from "react-spinners/BeatLoader";

import Lottie from 'react-lottie';
import animationData from '../../lotties/checkmark.json';

import '../../style/Modal.css';

function DataTransitionModal({ open, handleClose, data, message}) {
  const [loadingFlow1, setLoadingFlow1] = useState(true);
  const [loadingFlow2, setLoadingFlow2] = useState(true);
  const [userID, setUserID] = useState('');
  const [maskedPassword, setMaskedPassword] = useState('');
  const [tMsglist, setMsgList] = useState([]);

  useEffect(() => {
    if (open) {
      const id = data.username || data.email;
      const maskedPwd = data.password[0] + '*'.repeat(data.password.length - 1);
      const updatedMsgList = message.split(';').map(msg => 
        msg.replace('{userID}', id).replace('{maskedPassword}', maskedPwd)
      );
      
      setUserID(id);
      setMaskedPassword(maskedPwd);
      setMsgList(updatedMsgList)
      setLoadingFlow1(true);
      setLoadingFlow2(true);
      setTimeout(() => {setLoadingFlow1(false);}, 3000);
      setTimeout(() => {setLoadingFlow2(false);}, 8000);
    }
  }, [open]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className="modal-box">
          <Typography id="transition-modal-title" variant="h6" component="h2">
            Registration Status
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          <div>
            {loadingFlow1 ?
              (
                <div>
                  <p className="transition-message"> {tMsglist[0]} </p>
                  <div className="flex-transition-flow">
                    <svg version="1.1" id="Layer_1" width="50" height="50" viewBox="0 0 512 512" enable-background="new 0 0 512 512" ><g><circle fill="#547DBF" cx="256" cy="129.898" r="103.898"/><path fill="#547DBF" d="M448.284,404.885v36.027c0,24.906-20.182,45.088-45.088,45.088H108.804   c-24.906,0-45.088-20.182-45.088-45.088v-36.027c0-67.9,55.555-123.455,123.455-123.455h137.658   C392.729,281.43,448.284,336.985,448.284,404.885z"/></g></svg>                  
                    <BeatLoader
                      className="flex-flow-item"
                      color="#36d7b7"
                      loading={loadingFlow1}
                      size={40}
                    />
                    <svg viewBox="0 0 448 512" width="50" height="50"><path d="M448 73.12v45.75C448 159.1 347.6 192 224 192S0 159.1 0 118.9V73.12C0 32.88 100.4 0 224 0S448 32.88 448 73.12zM448 176v102.9C448 319.1 347.6 352 224 352S0 319.1 0 278.9V176c48.12 33.12 136.2 48.62 224 48.62S399.9 209.1 448 176zM448 336v102.9C448 479.1 347.6 512 224 512s-224-32.88-224-73.13V336c48.12 33.13 136.2 48.63 224 48.63S399.9 369.1 448 336z"/></svg>
                  </div>

                </div>
              ) :
              (
                <div>
                  <p className="transition-message"> {tMsglist[1]} </p>
                  <div className="flex-transition-flow">
                    <svg version="1.1" id="Layer_1" width="50" height="50" viewBox="0 0 512 512" enable-background="new 0 0 512 512" ><g><circle fill="#547DBF" cx="256" cy="129.898" r="103.898"/><path fill="#547DBF" d="M448.284,404.885v36.027c0,24.906-20.182,45.088-45.088,45.088H108.804   c-24.906,0-45.088-20.182-45.088-45.088v-36.027c0-67.9,55.555-123.455,123.455-123.455h137.658   C392.729,281.43,448.284,336.985,448.284,404.885z"/></g></svg>
                    <Lottie options={defaultOptions} height={100} width={100} style={{ marginTop:"0px", marginBottom: "0px" }}/>
                    <svg viewBox="0 0 448 512" width="50" height="50"><path d="M448 73.12v45.75C448 159.1 347.6 192 224 192S0 159.1 0 118.9V73.12C0 32.88 100.4 0 224 0S448 32.88 448 73.12zM448 176v102.9C448 319.1 347.6 352 224 352S0 319.1 0 278.9V176c48.12 33.12 136.2 48.62 224 48.62S399.9 209.1 448 176zM448 336v102.9C448 479.1 347.6 512 224 512s-224-32.88-224-73.13V336c48.12 33.13 136.2 48.63 224 48.63S399.9 369.1 448 336z"/></svg>
                  </div>
                  {loadingFlow2 ?
                    (
                      <div>
                        <p className="transition-message"> {tMsglist[2]} </p>
                        <div className="flex-transition-flow">
                          <svg viewBox="0 0 24 24" width="50" height="50" enable-background="new 0 0 24 24"><path d="M20 10H8V7.2c0-2.1 1.5-4 3.6-4.2C14 2.8 16 4.7 16 7c0 .6.4 1 1 1h1c.6 0 1-.4 1-1 0-3.8-3-6.9-6.8-7C8.3-.1 5 3.1 5 7v3H4c-1.1 0-2 .9-2 2v7c0 2.8 2.2 5 5 5h10c2.8 0 5-2.2 5-5v-7c0-1.1-.9-2-2-2zm-7 7.7V19c0 .5-.5 1-1 1s-1-.5-1-1v-1.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7z" fill="#547dbf" class="fill-000000"></path></svg>
                          <BeatLoader
                            className="flex-flow-item"
                            color="#36d7b7"
                            loading={loadingFlow2}
                            size={40}
                          />
                          <svg viewBox="0 0 448 512" width="50" height="50"><path d="M448 73.12v45.75C448 159.1 347.6 192 224 192S0 159.1 0 118.9V73.12C0 32.88 100.4 0 224 0S448 32.88 448 73.12zM448 176v102.9C448 319.1 347.6 352 224 352S0 319.1 0 278.9V176c48.12 33.12 136.2 48.62 224 48.62S399.9 209.1 448 176zM448 336v102.9C448 479.1 347.6 512 224 512s-224-32.88-224-73.13V336c48.12 33.13 136.2 48.63 224 48.63S399.9 369.1 448 336z"/></svg>
                      </div>
                      </div>
                    ) :
                    (
                      <div>
                        <p className="transition-message"> {tMsglist[3]} </p>
                        <div className="flex-transition-flow">
                          <svg viewBox="0 0 24 24" width="50" height="50" enable-background="new 0 0 24 24"><path d="M20 10H8V7.2c0-2.1 1.5-4 3.6-4.2C14 2.8 16 4.7 16 7c0 .6.4 1 1 1h1c.6 0 1-.4 1-1 0-3.8-3-6.9-6.8-7C8.3-.1 5 3.1 5 7v3H4c-1.1 0-2 .9-2 2v7c0 2.8 2.2 5 5 5h10c2.8 0 5-2.2 5-5v-7c0-1.1-.9-2-2-2zm-7 7.7V19c0 .5-.5 1-1 1s-1-.5-1-1v-1.3c-.6-.3-1-1-1-1.7 0-1.1.9-2 2-2s2 .9 2 2c0 .7-.4 1.4-1 1.7z" fill="#547dbf" class="fill-000000"></path></svg>
                          <Lottie options={defaultOptions} height={100} width={100} style={{ marginTop:"0px", marginBottom: "0px" }}/>
                          <svg viewBox="0 0 448 512" width="50" height="50"><path d="M448 73.12v45.75C448 159.1 347.6 192 224 192S0 159.1 0 118.9V73.12C0 32.88 100.4 0 224 0S448 32.88 448 73.12zM448 176v102.9C448 319.1 347.6 352 224 352S0 319.1 0 278.9V176c48.12 33.12 136.2 48.62 224 48.62S399.9 209.1 448 176zM448 336v102.9C448 479.1 347.6 512 224 512s-224-32.88-224-73.13V336c48.12 33.13 136.2 48.63 224 48.63S399.9 369.1 448 336z"/></svg>
                        </div>  
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>     
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
}

export default DataTransitionModal;
