import {React, useState} from 'react';
import "../../style/BasicLogin.css";
import GLOBAL_VARIABLES from '../globalVariables';
import DataTransitionModal from './DataTransitionModal';

import {
    TextField,
    InputAdornment,
    FormControl,
    InputLabel,
    IconButton,
    Button,
    Input,
    Stack,
    Alert
  } from "@mui/material";

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SignupIcon from '@mui/icons-material/PersonOutline';
import { registerUser } from "../helper/HelperFucntion";

const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

const transition_message = GLOBAL_VARIABLES["basic-signup-transition-message"];

function BasicSignupForm({ setChecked }) {
  
    const [showPassword, setShowPassword] = useState(false);
    // User Inputs
    const [userIdInput, setuserIdInput] = useState();
    const [emailInput, setEmailInput] = useState();
    const [passwordInput, setPasswordInput] = useState();
    const [confirmPasswordInput, setConfirmPasswordInput] = useState();

    // Inputs Errors
    const [userIdError, setuserIdError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);

    // Overall Form Validity
    const [formValid, setFormValid] = useState();
    const [success, setSuccess] = useState();

    // Set Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalData, setModalData] = useState({});

    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };

    // Internal Functions
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUsername = () => {
      if(!userIdInput ){
        setuserIdError(true);
        return;
      };

      setuserIdError(false);
    }

    const handleEmail = () => {
      console.log(isEmail(emailInput));
      if (!isEmail(emailInput)) {
        setEmailError(true);
        return;
      }
  
      setEmailError(false);
    };

    const handlePassword = () => {
      if (
        !passwordInput ||
        passwordInput.length < 5 ||
        passwordInput.length > 20
      ) {
        setPasswordError(true);
        return;
      }
      setPasswordError(false);
    };

    const validationCheck = () => {
      if (userIdError || !userIdInput) {
        setFormValid(
          "Username is set btw 5 - 15 characters long. Please Re-Enter"
        );
        return;
      }
      if (emailError || !emailInput) {
        setFormValid("Email is Invalid. Please Re-Enter");
        return;
      }
      if (passwordError || !passwordInput) {
        setFormValid(
          "Password is set btw 5 - 20 characters long. Please Re-Enter"
        );
        return;
      }
      if (passwordError || !confirmPasswordInput) {
        setFormValid(
          "Confirm Password is set btw 5 - 20 characters long. Please Re-Enter"
        );
        return;
      }
      if (passwordInput !== confirmPasswordInput) {
        setFormValid(
          "Passwords do not match, please ensure each password is the same"
        );
        return;
      }
      setFormValid(null);
    }

    const handleSubmit = async() => {
      const inputuser = {
          username: userIdInput,
          email: emailInput,
          password: passwordInput
      };

      // validation check
      validationCheck();

      // If validation check is passed, then register data to the back-end server
      var registeredData = await registerUser(inputuser);
      console.log(registeredData);

      // After registration completed, it receives the result
      if (registeredData) {
        setSuccess(`Form Submitted & Registered Successfully`);
        setModalMessage(transition_message);
        setModalData(inputuser); 
        handleOpenModal();
      }
    };

    return(
        <div className="inputForm">
            <p>
                <TextField 
                    id="standard-basic"
                    label="Username"
                    variant="standard"
                    fullWidth
                    size="small"
                    value={userIdInput}
                    error={userIdError}
                    InputProps={{}}
                    onChange={(event) => {
                      setuserIdInput(event.target.value);
                    }}
                    onBlur={handleUsername}                    
                />
            </p>
            <p>
                <TextField 
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    fullWidth
                    size="small"
                    value={emailInput}
                    error={emailError}
                    onChange={(event) => {
                      setEmailInput(event.target.value);
                    }}
                    onBlur={handleEmail}
                />
            </p>
            <p>
                <FormControl sx={{ m: 1, width: '100%', marginX:0, marginY:0 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={passwordInput}
                        error={passwordError}
                        onChange={(event) => {
                          setPasswordInput(event.target.value);
                        }}
                        onBlur={handlePassword}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            </p>
            <p>
                <FormControl sx={{ m: 1, width: '100%', marginX:0, marginY:0 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-password">Confirm Password</InputLabel>
                    <Input
                        id="standard-adornment-password"
                        type={showPassword ? 'text' : 'password'}
                        value={confirmPasswordInput}
                        error={passwordError}
                        onChange={(event) => {
                          setConfirmPasswordInput(event.target.value);
                        }}
                        onBlur={handlePassword}
                        endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        }
                    />
                </FormControl>
            </p>

            <div style={{ marginTop: "30px" }}>
                <Button
                    variant="contained"
                    fullWidth
                    startIcon={<SignupIcon />}
                    onClick={handleSubmit}
                >
                SIGN UP
                </Button>
            </div>

            {formValid && (
              <Stack sx={{ width: "100%", paddingTop: "10px" }} spacing={2}>
                <Alert severity="error" size="small">
                  {formValid}
                </Alert>
              </Stack>
            )}

            {success && (
              <Stack sx={{ width: "100%", paddingTop: "10px" }} spacing={2}>
                <Alert severity="success" size="small">
                  {success}
                </Alert>
              </Stack>
            )}

            <div style={{ marginTop: "7px", fontSize: "10px" }} margin="left">
              <a>Forgot Password</a>
              <br />
              Do you have an account ?{" "}
              <small style={{ textDecoration: "underline", color: "blue" }} onClick={() => setChecked(false)} >
                Log In
              </small>
            </div>

            <DataTransitionModal open={openModal} handleClose={handleCloseModal} data={modalData} message={modalMessage} />
        </div>
    );
}

export default BasicSignupForm;
