import React from 'react';
import { Box, Button, useMediaQuery, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import PAGES from '../enums';

function Landing() {
  const matches = useMediaQuery('(max-width:600px)');

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 2,
      marginTop: 8,
    }}>
      {matches ? (
        <Typography variant="h5" style={{ textAlign: 'center', color: 'white'}}>Learning Login Platform</Typography>
      ) : (
        <Typography variant="h4" style={{ textAlign: 'center', color: 'white'}}>Learning Login Platform</Typography>
      )}
      <Box sx={{
          width: '400px',
          display: 'flex', 
          flexDirection: 'column',
          gap: 3
      }}>
        <Button
          variant="contained"
          component={Link} to={PAGES["basic-auth-login"]}
          sx={{
            width: '100%',
            minHeight: '65px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '0'
          }}
        >
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            Start Learning with Password
          </Typography>
        </Button>
        <Button
          variant="contained" 
          component={Link}
          to={PAGES["passwordless-auth-login"]}
          sx={{
            width: '100%',
            minHeight: '65px',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '0'
          }}
        >
          <Typography variant="button" sx={{ fontWeight: 'bold' }}>
            Start Learning without Password (Passwordless Login)
          </Typography>
        </Button>
        {/* <Button variant="contained" component={Link} to={PAGES["sns-auth-login"]} sx={{ minWidth: '200px' }}>Social Media Authentication</Button> */}
      </Box>
    </Box>
  );
}

export default Landing;