import './App.css';
import { BrowserRouter as Router} from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import PAGES from "./components/enums";
import Landing from "./components/pages/Landing";
import BasicLogin from "./components/pages/BasicLogin";
import WebAuthLogin from "./components/pages/WebAuthLogin";
import SNSAuthLogin from "./components/pages/SNSAuthLogin";

function App() {
  return (
    <Router>
      <Routes>
        <Route path={PAGES["landing"]} element={<Landing />} />
        <Route path={PAGES["basic-auth-login"]} element={<BasicLogin />} />
        <Route path={PAGES["passwordless-auth-login"]} element={<WebAuthLogin />} />
        <Route path={PAGES["sns-auth-login"]} element={<SNSAuthLogin />} />
      </Routes>
    </Router>
  );
}

export default App;
