import {React, useState} from 'react';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import SingupIcon from '@mui/icons-material/PersonOutline';
import LoginIcon from '@mui/icons-material/Https';
import Switch from '@mui/material/Switch';
import WebAuthLoginForm from './WebAuthLoginForm';
import WebAuthSignupForm from './WebAuthSignUpForm';

import "../../style/BasicLogin.css";

function WebAuthLogin() {
    const [checked, setChecked] = useState(false);
    const handleChange = (event) => {
      setChecked(event.target.checked);
    };

  return(
    <div className="console">
        <Paper elevation={3} style={{ width: 'auto', margin: 'auto', marginTop: '30px', minWidth: '300px' }}>
            <div className="dialog" align="center" style={{ padding: "20px", paddingBottom: "30px" }}>
                {checked ? (
                    <Chip icon={<SingupIcon />} label="Sign Up" color="primary" variant="outlined" />
                ) : (
                    <Chip icon={<LoginIcon />} label="Log In" color="primary" variant="outlined" />
                )}
                <br />
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                {checked ? <WebAuthSignupForm setChecked={setChecked} />: <WebAuthLoginForm />}
            </div>
        </Paper>
    </div>
  );
}

export default WebAuthLogin;
