import {React, useState} from 'react';
import "../../style/WebAuthLogin.css";
import GLOBAL_VARIABLES from '../globalVariables';
import DataTransitionModal from './DataTransitionModal';

import {
    TextField,
    Button,
    Stack,
    Alert
  } from "@mui/material";

import SignupIcon from '@mui/icons-material/PersonOutline';
import { registerUser, bufferToBase64URLString } from "../helper/HelperFucntion";

const server_url = GLOBAL_VARIABLES["web-auth-api"];
const generate_registration_options_url = server_url + "/generate_registration_options";
const verify_registration_url = server_url + "/verify_registration";
const transition_message = GLOBAL_VARIABLES["webauth-signup-transition-message"];

function WebAuthSignUpForm({ setChecked }) {
  
    // User Inputs
    const [userIdInput, setuserIdInput] = useState();
    const [userIdError, setuserIdError] = useState(false);

    // Overall Form Validity
    const [formValid, setFormValid] = useState();
    const [success, setSuccess] = useState();

    // Set Modal Variables
    const [openModal, setOpenModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [modalData, setModalData] = useState({});

    const handleOpenModal = () => {
      setOpenModal(true);
    };
  
    const handleCloseModal = () => {
      setOpenModal(false);
    };

    const handleUsername = () => {
      if(!userIdInput ){
        setuserIdError(true);
        return;
      };

      setuserIdError(false);
    }

    const validationCheck = () => {
      if (userIdError || !userIdInput) {
        setFormValid(
          "Username is set btw 5 - 15 characters long. Please Re-Enter"
        );
        return;
      }
      setFormValid(null);
    }

    const handleSubmit = async() => {
      // validation check
      validationCheck();

      const response = await userVerifyRegistration();
      // const publicKey = response["credential_public_key"];
      const credentialData = JSON.parse(localStorage.getItem('credentialData'));

      const inputuser = {
        username: userIdInput,
        password: credentialData.rawId
      };

      if(response.user_verified) {
        // If validation check is passed, then register data to the back-end server
        var registeredData = await registerUser(inputuser);
        console.log(registeredData);

        // After registration completed, it receives the result
        if (registeredData) {
          setSuccess(`Form Submitted & Registered Successfully`);
          setModalMessage(transition_message);
          setModalData(inputuser); 
          handleOpenModal();
        }
      }
    };
  
    const userVerifyRegistration = async() => {
        const userId = "auth" + generateRandomDigits(); // userId を適切なバイナリ形式 (Uint8Array) に変換
        const encoder = new TextEncoder();
        const userIdBuffer = encoder.encode(userId);  // Uint8Array に変換        

        const rpId = new URL(server_url).hostname;
        const origin = window.location.origin;
        // rpIdとoriginのデバッグ用ログ
        console.log("rpId:", rpId);
        console.log("origin:", origin);

        const response = await fetch(generate_registration_options_url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type',
            },
            body: JSON.stringify({
                // rp_id: "localhost", //Dev Enrionment: Access URL has own Flask App(localhost) hence the rp_id is localhost 
                rp_id: "nextgenauth.net",
                rp_name: origin,
                // user_id: userId,
                user_id: userIdBuffer,
                user_name: "test",
                user_display_name: "test"
            }),
        });

        const options = await response.json();
        console.log(options)
        options.challenge = new Uint8Array(options.challenge);
        options.user.id = new Uint8Array(options.user.id);
        options.excludeCredentials = options.excludeCredentials.map(cred => ({
            ...cred,
            id: new Uint8Array(cred.id)
        }));
        try {
            const credential = await navigator.credentials.create({ publicKey: options });
            if (!credential) {
                throw new Error('Registration was not completed');
            }
            // Handle successful registration (e.g., send credential to the server)
            const credentialData = {
                id: credential.id,
                rawId: bufferToBase64URLString(credential.rawId),
                response: {
                    attestationObject: bufferToBase64URLString(credential.response.attestationObject),
                    clientDataJSON: bufferToBase64URLString(credential.response.clientDataJSON),
                    transports: credential.response.getTransports ? credential.response.getTransports() : undefined
                },
                type: credential.type,
                clientExtensionResults: credential.getClientExtensionResults(),
                authenticatorAttachment: credential.authenticatorAttachment
            };
            const verification_response = await fetch(verify_registration_url, {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type',
                },
                body: JSON.stringify({
                    credential: credentialData,
                    expectedChallenge: bufferToBase64URLString(options.challenge.buffer),
                    expectedOrigin: window.location.origin,
                    expectedRpId: "nextgenauth.net",
                }),
            });

            if (!verification_response.ok) {
              throw new Error(`HTTP error! status: ${verification_response.status}`);
            }

            const verification_response_json = await verification_response.json();
            localStorage.setItem('user', userId);
            localStorage.setItem('challenge', bufferToBase64URLString(options.challenge.buffer));
            localStorage.setItem('credentialData', JSON.stringify(credentialData));
            localStorage.setItem('publicKey', verification_response_json["credential_public_key"]);
            localStorage.setItem('signCount', verification_response_json["sign_count"]);
            console.log("verification_response_json:", verification_response_json);
            return verification_response_json

        } catch (error) {
            console.error('Registration failed:', error);
        }
    }

    const generateRandomDigits = () => {
      let min = Math.pow(10, 9);
      let max = Math.pow(10, 10) - 1;
      return Math.floor(min + Math.random() * (max - min + 1));
    }

    return(
        <div className="inputForm">
            <p>
                <TextField 
                    id="standard-basic"
                    label="Username/Email"
                    variant="standard"
                    fullWidth
                    size="small"
                    value={userIdInput}
                    error={userIdError}
                    InputProps={{}}
                    onChange={(event) => {
                      setuserIdInput(event.target.value);
                    }}
                    onBlur={handleUsername}                    
                />
            </p>
            <div style={{ marginTop: "30px" }}>
                <Button
                    variant="contained"
                    fullWidth
                    startIcon={<SignupIcon />}
                    onClick={handleSubmit}
                >
                SIGN UP
                </Button>
            </div>

            {formValid && (
              <Stack sx={{ width: "100%", paddingTop: "10px" }} spacing={2}>
                <Alert severity="error" size="small">
                  {formValid}
                </Alert>
              </Stack>
            )}

            {success && (
              <Stack sx={{ width: "100%", paddingTop: "10px" }} spacing={2}>
                <Alert severity="success" size="small">
                  {success}
                </Alert>
              </Stack>
            )}

            <DataTransitionModal open={openModal} handleClose={handleCloseModal} data={modalData} message={modalMessage} />
        </div>
    );
}

export default WebAuthSignUpForm;
